import React from "react";
import "./DropZone.css";

export const DropZoneInternalError = React.memo(() => (
  <>
    <div className="errorMessage">Service Unavailable</div>
    <div className="errorSubMessage">
      The service is currently unavailable. Please try again later.
    </div>
  </>
));
