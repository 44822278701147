import React from "react";
import "./DropZone.css";
import { DropZoneFileSizeError } from "./DropZoneFileSizeError";
import { DropZoneFileTypeError } from "./DropZoneFileTypeError";
import { DropZoneInternalError } from "./DropZoneInternalError";

export interface DropZoneErrorProps {
  error: string;
  handleReset: () => void;
}

export const DropZoneError = React.memo(
  (props: React.PropsWithChildren<DropZoneErrorProps>) => (
    <>
      {props.error === "UnsupportedFileSize" ? <DropZoneFileSizeError /> : null}

      {props.error === "UnsupportedFileType" ? <DropZoneFileTypeError /> : null}

      {props.error === "InternalError" ? <DropZoneInternalError /> : null}

      <div className="submitButton" onClick={() => props.handleReset()}>
        Try again
      </div>
    </>
  )
);
