import React from "react";
import "./DropZone.css";
import { ReactComponent as UploadImg } from "../../assets/DocumentArrowUp.svg";
import { convertUnits } from "../../utils/unitsConverter";
import StorageService from "../../services/storage.service";
import DataService from "../../services/data.service";

export interface DropZoneFileSubmitProps {
  files: File[];
  handleReset: () => void;
  handleUpload: (files: File[], storageService: any, dataService: any) => void;
  inputFile: React.RefObject<HTMLInputElement>;
}

export const DropZoneFileSubmit = React.memo(
  (props: React.PropsWithChildren<DropZoneFileSubmitProps>) => {
    const [isButtonClicked, setIsButtonClicked] = React.useState(false);

    const handleClick = () => {
      props.handleUpload(props.files, StorageService, DataService);
      setIsButtonClicked(true);
    };

    return (
      <>
        <ul>
          {props.files.map((file: File) => (
            <li key={`$file.name}_${file.lastModified}`}>
              <div className="fileName">{file.name}</div>{" "}
              <div className="fileSize">{convertUnits(file.size)}</div>
            </li>
          ))}
        </ul>
        <div
          className="submitButton"
          onClick={isButtonClicked ? undefined : handleClick}
        >
          Submit
        </div>
        <div className="selectNewFile">
          <UploadImg width="30" height="30" stroke="#757575" />
          <div
            className="addNewFile"
            onClick={() => props.inputFile.current?.click()}
          >
            Select a new file
          </div>
        </div>
      </>
    );
  }
);
