import { DropZone } from "../DropZone/DropZone";
import "./FileUploader.css";
import React, { ChangeEvent, useRef } from "react";
import classNames from "classnames";
import ShortlinkResponse from "../../types/shortlinkResponse.type";
import UploadProgressContext from "../../contexts/UploadProgressContext";
import { DropZoneFileSelect } from "../DropZone/DropZoneFileSelect";
import { DropZoneFileSubmit } from "../DropZone/DropZoneFileSubmit";
import { DropZoneUploadProgress } from "../DropZone/DropZoneUploadProgress";
import { DropZoneError } from "../DropZone/DropZoneError";
import { DropZoneShareFile } from "../DropZone/DropZoneShareFile";

export const FileUploader = React.memo(() => {
  const { uploadProgress, setUploadProgress } = React.useContext(
    UploadProgressContext
  );
  const [isDropActive, setIsDropActive] = React.useState(false);
  const [files, setFiles] = React.useState<File[]>([]);
  const [isDropped, setIsDropped] = React.useState(false);
  const [shortLink, setShortlink] = React.useState("");
  const [error, setError] = React.useState("");
  var inputFile = useRef<HTMLInputElement>(null);

  // EVENT HANDLERS

  const onDragStateChange = React.useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);

  const onFilesDrop = React.useCallback((files: any) => {
    setIsDropped(true);
    setFiles(files);
  }, []);

  const handleSelectFiles = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // this change event will only fire if the user actually selects a file, so this
      // null and length check should never return true. however, doing this check doesn't
      // harm and can potentially save you from unexpected situations where, due to some
      // bug or non-standard behavior, the 'change' event gets fired without any files
      // being selected
      if (e.target.files === null || e.target.files.length === 0) {
        return;
      }

      const files = Array.from(e.target.files);

      setIsDropped(true);
      setFiles(files);
    },
    []
  );

  const handleUpload = React.useCallback(
    async (files: any, storageService: any, dataService: any) => {
      //   setUploadProgress("Dumping...");

      try {
        for (let i = 0; i < files?.length; i++) {
          let file = files[i];

          let objectKey = await storageService.uploadFile(
            file,
            setUploadProgress
          );

          let res: ShortlinkResponse = await dataService.saveFileData(
            objectKey,
            file
          );

          setShortlink(res.shortlink);
          setIsDropped(false);
        }
      } catch (error) {
        let message;
        if (error instanceof Error) {
          message = error.name;
        } else {
          message = "InternalError";
        }
        setError(message);
        setUploadProgress("");
      }
    },
    [setUploadProgress]
  );

  const handleReset = React.useCallback(async () => {
    setIsDropActive(false);
    setIsDropped(false);
    setUploadProgress("");
    setError("");
    setShortlink("");
    // @ts-ignore (us this comment if typescript raises an error)
    inputFile.current.value = "";
  }, [setUploadProgress]);

  const isSubmittingFile = () => {
    return isDropped && !uploadProgress && !isError();
  };

  const isSelectingFile = () => {
    return !isDropped && !uploadProgress;
  };

  const isUploadingFile = () => {
    return uploadProgress && !shortLink;
  };

  const isSharingFile = () => {
    return shortLink.trim().length !== 0;
  };

  const isError = () => {
    return error.trim().length !== 0;
    //return error != null && Object.keys(error).length != 0;
    //return false;
  };

  const isDropZoneActive = () => {
    return (
      (isDropActive && !shortLink && !isError()) ||
      (isDropped && !shortLink && !isError())
    );
  };

  return (
    <div>
      <div className="fileUploaderContainer">
        <div
          className={classNames(
            "dropZoneWrapper",
            { dropZoneActive: isDropZoneActive() },
            { error: isError() }
          )}
        >
          <DropZone
            onDragStateChange={onDragStateChange}
            onFilesDrop={onFilesDrop}
          >
            <input
              type="file"
              ref={inputFile}
              onChange={handleSelectFiles}
              multiple={false}
              style={{ display: "none" }}
            />

            {isSelectingFile() ? (
              <DropZoneFileSelect inputFile={inputFile} />
            ) : null}

            {isSubmittingFile() ? (
              <DropZoneFileSubmit
                files={files}
                handleReset={handleReset}
                handleUpload={handleUpload}
                inputFile={inputFile}
              />
            ) : null}

            {isUploadingFile() ? (
              <DropZoneUploadProgress uploadProgress={uploadProgress} />
            ) : null}

            {isSharingFile() ? (
              <DropZoneShareFile
                shortLink={shortLink}
                handleReset={handleReset}
              />
            ) : null}

            {isError() ? (
              <DropZoneError error={error} handleReset={handleReset} />
            ) : null}
          </DropZone>
        </div>
      </div>
      {/* <div className='dropZoneFooter'>Please be mindful that anyone who recieves the link you generate for the file will be able to access it.</div> */}
    </div>
  );
});
