import React from "react";
import axios from "axios";
import SignedUrlRequest from "../types/signedUrlRequest.type";
import UnsupportedFileType from "../errors/UnsupportedFileType";
import UnsupportedFileSize from "../errors/UnsupportedFileSize";
import InternalError from "../errors/InternalError";
import { StatusCodes } from "../constants/StatusCodes";
import SignedUrlResponse from "../types/signedUrlResponse.type";
import FileUploadError from "../errors/FileUploadError";

class StorageService {
  async getSignedUrl(file: File) {
    const reqData: SignedUrlRequest = {
      fileExt: file.name.toLowerCase().split(".").pop()!,
      fileSize: file.size.toString(),
    };
    const http = axios.create({
      headers: { "Content-type": "application/json" },
    });
    try {
      const response = await http.post<any>(
        `https://api.dumpit.gg/signedUrl`,
        reqData
      );
      return response.data;
    } catch (error) {
      if (error.response.status === StatusCodes.UNSUPPORTED_MEDIA_TYPE) {
        throw new UnsupportedFileType();
      }
      if (error.response.status === StatusCodes.PAYLOAD_TOO_LARGE) {
        throw new UnsupportedFileSize();
      }
      throw new InternalError();
    }
  }

  async uploadFile(
    file: any,
    setUploadProgress: React.Dispatch<React.SetStateAction<string>>
  ) {
    try {
      const response: SignedUrlResponse = await this.getSignedUrl(file);
      let form = new FormData();
      Object.keys(response.fields).forEach((key) =>
        form.append(key, (response.fields as any)[key])
      );
      form.append("file", file);

      const http = axios.create({
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          if (total) {
            let percent = Math.floor((loaded * 100) / total);
            setUploadProgress(percent.toString());
          }
        },
      });
      await http.post(response.url, form);
      return (response.fields as any)["key"];
    } catch (error) {
      if (error.name === "UnsupportedFileSize") {
        throw new UnsupportedFileSize();
      }
      if (error.name === "UnsupportedFileType") {
        throw new UnsupportedFileType();
      }
      throw new FileUploadError(error.message);
    }
  }
}

export default new StorageService();
