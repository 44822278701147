import React from "react";
import "./DropZone.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export interface DropZoneUploadProgressProps {
  uploadProgress: string;
}

export const DropZoneUploadProgress = React.memo(
  (props: React.PropsWithChildren<DropZoneUploadProgressProps>) => (
    <div style={{ width: 500, height: 500 }}>
      <CircularProgressbar
        value={parseInt(props.uploadProgress)}
        text={`${props.uploadProgress}%`}
        styles={buildStyles({
          // rotation of path and trail, in number of turns (0-1)
          rotation: 0.25,
          // whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "butt",
          textSize: "16px",
          // how long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,
          pathColor: `rgba(116, 201, 55, ${
            parseInt(props.uploadProgress) / 100
          })`,
          textColor: "#74C937",
          trailColor: "#1D1D1D",
          backgroundColor: "#1D1D1D",
        })}
      />
    </div>
  )
);
