import React from "react";
import { Header } from "./Header/Header";
import { FileUploader } from "./FileUploader/FileUploader";
import { Footer } from "./Footer/Footer";
import UploadProgressContext from "../contexts/UploadProgressContext";

export function Home() {
  const [uploadProgress, setUploadProgress] = React.useState("");

  return (
    <>
      <Header />
      {/* 'Provider' is a react component that allows you to set a value for the context. any
                 component inside this provider can access the value of the context */}
      <UploadProgressContext.Provider
        value={{ uploadProgress, setUploadProgress }}
      >
        <FileUploader />
      </UploadProgressContext.Provider>
      <Footer />
    </>
  );
}
