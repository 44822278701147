import React from "react";
import "./DropZone.css";

export const DropZoneFileSizeError = React.memo(() => (
  <>
    <div className="errorMessage">File too large</div>
    <div className="errorSubMessage">
      Files larger than 200MB are not supported.
    </div>
  </>
));
