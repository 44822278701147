import "./Footer.css";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <a href="mailto: contact@dumpit.gg" className="contact">
          Contact
        </a>
        <Link to="/terms" className="tos">
          Terms of Service
        </Link>
      </div>
    </div>
  );
}
