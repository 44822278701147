import React from "react";
import "./DropZone.css";

export const DropZoneFileTypeError = React.memo(() => (
  <>
    <div className="errorMessage">Unsupported file type</div>
    <div className="errorSubMessage">
      Please review the FAQ for the current list of supported file types.
    </div>
  </>
));
