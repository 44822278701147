import axios from "axios";
import ShortlinkRequest from "../types/shortlinkRequest.type";
import ShortlinkResponse from "../types/shortlinkResponse.type";

class DataService {

    async saveFileData(objectKey: string, file: File) {
        let reqData: ShortlinkRequest = {
            fileId: objectKey.split('.')[0],
            fileExt: objectKey.split('.')[1],
            fileName: file.name,
            fileSize: file.size.toString()
        }
        const http = axios.create({
            headers: {"Content-type": "application/json"}
        })

        const response = await http.post<ShortlinkResponse>(`https://api.dumpit.gg/shortlink`, reqData)
        return response.data
       
    }
}

export default new DataService()