import "./Terms.css";

export function Terms() {
  return (
    <>
      <div className="terms-container">
        <h1>TERMS OF SERVICE</h1>

        <h2>1. Introduction</h2>
        <p>
          This Terms of Service ("Agreement") is entered into between you
          ("User" or "You") and the operators of dumpit.gg ("Company", "We",
          "Us", or "Our") - the provider of the file sharing site, located at
          dumpit.gg ("Website"). By using Our Website, its content, and the
          file-sharing services provided on the Website ("Services"), You agree
          to be bound by these Terms of Service, as well as any other rules or
          policies that are communicated to you from time to time by dumpit.gg.
        </p>

        <h2>2. Access to Services</h2>
        <p>
          The Services are intended only for users who are 13 years of age or
          older. If you are under 13, you are not permitted to use the Services.
          By agreeing to these Terms of Service, you represent that you are at
          least 13.
        </p>

        <h2>3. Content</h2>
        <p>
          You acknowledge that all information, data, text, images, video, or
          other materials ("Content") shared on the Website are the sole
          responsibility of the person from whom such Content originated. This
          means that You, not dumpit.gg, are entirely responsible for all
          Content that you upload, post, email, transmit, or otherwise make
          available via the Services.
        </p>

        <h2>4. Content Removal Requests</h2>
        <p>
          You have the right to request removal of Content from the Services.
          This includes Content you have uploaded yourself, or Content that you
          own but was uploaded by another user. To request removal, please email
          us at contact@dumpit.gg with the subject line "Content Removal
          Request". Include specific details about the Content you wish to be
          removed, and provide proof of ownership if the Content was not
          uploaded by you. We will make reasonable efforts to address your
          request promptly, but we cannot guarantee immediate removal in all
          circumstances.
        </p>

        <h2>5. Copyright and Intellectual Property Rights</h2>
        <p>
          You represent and warrant that the Content you share via the Services
          does not infringe the intellectual property rights or other
          proprietary rights, including copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </p>

        <h2>6. User Conduct</h2>
        <p>You agree to not use the Services to:</p>

        <p>
          Upload, post, email, transmit or otherwise make available any Content
          that is unlawful, harmful, threatening, abusive, harassing, tortious,
          defamatory, vulgar, obscene, libelous, invasive of another's privacy,
          hateful, or racially, ethnically or otherwise objectionable;
        </p>
        <p>Harm minors in any way;</p>
        <p>
          Impersonate any person or entity, including, but not limited to, a
          Company official, or falsely state or otherwise misrepresent your
          affiliation with a person or entity;
        </p>
        <p>
          Upload, post, email, transmit or otherwise make available any Content
          that you do not have a right to make available under any law or under
          contractual or fiduciary relationships.
        </p>

        <h2>7. Limitation of Liability</h2>
        <p>
          The Company, its officers, directors, employees, or agents, shall not
          be liable for any direct, indirect, incidental, special, consequential
          or exemplary damages, resulting from any aspect of your use of the
          Website or the Service.
        </p>

        <h2>8. Indemnity</h2>
        <p>
          You agree to indemnify and hold dumpit.gg, its officers, directors,
          employees, agents, harmless from any claims, damages, obligations,
          losses, liabilities, costs or debt, and expenses arising from your use
          of the Services.
        </p>

        <h2>9. Termination</h2>
        <p>
          dumpit.gg reserves the right to suspend or terminate your access to
          the Services with or without notice, for any reason or no reason.
        </p>

        <h2>10. Modification of Terms</h2>
        <p>
          The Company reserves the right to modify these Terms of Service at any
          time. It is your responsibility to check these Terms of Service
          periodically for changes.
        </p>

        <h2>11. Governing Law</h2>
        <p>
          These Terms of Service and the relationship between you and dumpit.gg
          shall be governed by the laws of the state in which the dumpit.gg is
          registered, without regard to its conflict of law provisions.
        </p>

        <h2>12. IP Address Storage</h2>
        <p>
          We record and store IP addresses of users for operational and security
          reasons. By using our Services, you agree and consent to the
          collection and storage of your IP address.
        </p>

        <h2>13. Right to Delete Content</h2>
        <p>
          We reserve the right to delete or remove any content uploaded to our
          Services at any time and for any reason, at our sole discretion,
          without notice. This can be due to legal and policy reasons, or any
          other reason we deem necessary.
        </p>

        <h2>14. Acceptance of Terms</h2>
        <p>
          By using the Services, you signify your acceptance of these Terms of
          Service. If you do not agree to these Terms of Service, please do not
          use the Services.
        </p>

        <p>Last updated: June 30, 2023</p>
      </div>
    </>
  );
}
