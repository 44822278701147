import React from "react";
import "./DropZone.css";

export interface DropZoneShareFileProps {
  shortLink: string;
  handleReset: () => void;
}

export const DropZoneShareFile = React.memo(
  (props: React.PropsWithChildren<DropZoneShareFileProps>) => (
    <>
      <h1>share</h1>
      <a className="shortlink" href={`https://${props.shortLink}`}>
        {props.shortLink}
      </a>
      <h2 className="reset" onClick={() => props.handleReset()}>
        new
      </h2>
    </>
  )
);
